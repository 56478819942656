<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title class="pb-2">
        COMITÉS

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">

          <v-btn
            color="primary"
            class="mb-1 me-3"
            :to="{ name: 'committee-new'}"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Nuevo</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-1"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider class="mt-2"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0 pl-0 pt-0">
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="newItems"
        :options.sync="options"
        :loading="loading"
      >
        <!-- code -->
        <template #[`item.code`]="{item}">
          <div class="ml-3">
            <router-link
              :to="{ name: 'committee-id',  params: { id: item.id }}"
            >
              {{item.code}}
            </router-link>
          </div>
        </template>

        <template #[`item.users`]="{item}">
          <div
            class="v-avatar-group2"
            :class="rootThemeClasses"
          >
            <v-tooltip
              v-for="user of item.users"
              :key="user.id"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  @click="showUser($event, user)"
                  :color="user.avatar ? '' : 'primary'"
                  :class="user.avatar ? '' : 'v-avatar-light-bg primary--text pointer'"
                  size="35"
                >
                  <v-img
                    v-if="user.avatar"
                    :src="require(`@/assets/images/avatars/${user.avatar}`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(user.fullname) }}</span>
                </v-avatar>
              </template>
              <span>{{ user.fullname }}</span>
            </v-tooltip>
          </div>
        </template>

        <!-- status -->
        <template #[`item.stated_at`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.stated_at)"
            :class="`${resolveUserStatusVariant(item.stated_at)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.stated_at ? 'Activo' : 'Inactivo'}}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
          <!-- delete -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="setCommittee(item)"
                  color="#4CAF50"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <!-- view  -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                >
                  <v-icon size="18">
                    {{ icons.mdiCancel }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Inactivar</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="300"
      :position-x="user.x"
      :position-y="user.y"
      offset-y
    >
      <v-card class="py-1 elevation-6" outlined>
        <v-list>
          <v-list-item>
            <v-list-item-avatar
              :color="user.avatar ? '' : 'primary'"
              :class="user.avatar ? '' : 'v-avatar-light-bg primary--text pointer'"
            >
              <img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="user.fullname"
              >
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(user.fullname) }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.fullname }}</v-list-item-title>
              <v-list-item-subtitle><small>{{ user.email }}</small></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiCancel,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import useCommitteeList from './useCommitteeList'
import { useRouter } from '@core/utils'
import useVuetify from '@core/utils/vuetify'

export default {
  components: { FilterBy },
  setup() {
    const menu = ref(false)
    const user = ref({})
    const {
      committeeListTable,
      tableColumns,
      roleOptions,
      totalcommitteeListTable,
      loading,
      options,

      fetchUsers,
      filterFields,
      filterLists,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = useCommitteeList()

    const isAddNewUserSidebarActive = ref(false)

    const newItems = ref([])
    const { router } = useRouter()

    const { rootThemeClasses } = useVuetify()

    watch(
      committeeListTable,
      () => {
        newItems.value = committeeListTable.value
      },
      {
        deep: true,
      },
    )

    const showUser = (e, userData) => {
      user.value = userData
      user.value.x = e.x
      user.value.y = e.y
      menu.value = true
    }

    const setCommittee = item => {
      router.push({ name: 'committee-id', params: { id: item.id } })
    }

    return {
      filterFields,
      filterLists,
      menu,
      newItems,
      committeeListTable,
      tableColumns,
      setCommittee,
      totalcommitteeListTable,
      roleOptions,
      rootThemeClasses,
      showUser,
      loading,
      options,
      isAddNewUserSidebarActive,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchUsers,
      user,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiPencilOutline,
        mdiDotsVertical,
        mdiCancel,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.pointer {
  cursor: pointer;
}
.column-user {
  width: 200px !important;
}
</style>

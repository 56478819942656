var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-2"},[_vm._v(" COMITÉS "),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-1 me-3",attrs:{"color":"primary","to":{ name: 'committee-new'}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nuevo")])],1),_c('v-btn',{staticClass:"mb-1",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Exportar")])],1)],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0 pl-0 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('filter-by',{attrs:{"filterFields":_vm.filterFields,"filterLists":_vm.filterLists},model:{value:(_vm.newItems),callback:function ($$v) {_vm.newItems=$$v},expression:"newItems"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.newItems,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-3"},[_c('router-link',{attrs:{"to":{ name: 'committee-id',  params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-avatar-group2",class:_vm.rootThemeClasses},_vm._l((item.users),function(user){return _c('v-tooltip',{key:user.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({class:user.avatar ? '' : 'v-avatar-light-bg primary--text pointer',attrs:{"color":user.avatar ? '' : 'primary',"size":"35"},on:{"click":function($event){return _vm.showUser($event, user)}}},'v-avatar',attrs,false),on),[(user.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (user.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(user.fullname)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(user.fullname))])])}),1)]}},{key:"item.stated_at",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.stated_at)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.stated_at)}},[_vm._v(" "+_vm._s(item.stated_at ? 'Activo' : 'Inactivo')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"#4CAF50"},on:{"click":function($event){return _vm.setCommittee(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCancel)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Inactivar")])])],1)]}}],null,true)})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"300","position-x":_vm.user.x,"position-y":_vm.user.y,"offset-y":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"py-1 elevation-6",attrs:{"outlined":""}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{class:_vm.user.avatar ? '' : 'v-avatar-light-bg primary--text pointer',attrs:{"color":_vm.user.avatar ? '' : 'primary'}},[(_vm.user.avatar)?_c('img',{attrs:{"src":_vm.user.avatar,"alt":_vm.user.fullname}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(_vm.user.fullname)))])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.fullname))]),_c('v-list-item-subtitle',[_c('small',[_vm._v(_vm._s(_vm.user.email))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
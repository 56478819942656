import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiAccountGroupOutline,
  mdiAccountCogOutline,
  mdiDnsOutline,
  mdiAccountCashOutline,
  mdiAccountEditOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useCommitteeList() {

  const committeeListTable = ref([])

  const tableColumns = [
    { text: 'CÓDIGO', value: 'code' },
    { text: 'DESCRIPCIÓN', value: 'description' },
    { text: 'PARTICIPANTES', value: 'users', sortable: false },
    { text: 'ESTADO', value: 'stated_at', sortable: false },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const filterLists = ref([])
  filterLists.value =  [{
    value: 'stated_at',
    list: [{text: 'Activo', value: true}, {text: 'Inactivo', value: false}]
  }]


  const filterFields = [
    { text: 'CÓDIGO', value: 'code', type: 'text' },
    { text: 'DESCRIPCIÓN', value: 'description', type: 'text'},
    { text: 'ESTADO', value: 'stated_at', type: 'lookup' },
  ]

  const roleOptions = [
    { text: 'Administrador', value: 'admin' },
    { text: 'Gestor', value: 'project_manager' },
    { text: 'Comité Evaluador', value: 'commitee' },
    { text: 'Auditor', value: 'auditor' },
    { text: 'Patrocinador', value: 'sponsor' },
    { text: 'Miembro', value: 'team_member' },
  ]

  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })

  // fetch data
  const fetchCommittees = () => {
    loading.value = true
    store
      .dispatch('committees/fetchCommittees')
      .then(response => {

        const data = ref([])
        if (response) data.value = JSON.parse(response[0].listcommittees)['committees']
        committeeListTable.value = data.value ? data.value : []
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }

  fetchCommittees()

  watch([], () => {
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'team_member') return 'secondary'
    if (role === 'commitee') return 'warning'
    if (role === 'project_manager') return 'info'
    if (role === 'sponsor') return 'success'
    if (role === 'admin') return 'error'
    if (role === 'auditor') return 'accent'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'team_member') return mdiAccountOutline
    if (role === 'commitee') return  mdiAccountGroupOutline
    if (role === 'project_manager') return mdiAccountCogOutline
    if (role === 'sponsor') return mdiAccountCashOutline
    if (role === 'admin') return mdiDnsOutline
    if (role === 'auditor') return mdiAccountEditOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    else return 'error'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    filterFields,
    filterLists,
    committeeListTable,
    tableColumns,
    loading,
    options,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    roleOptions
  }
}
